import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import HomeIcon from '@material-ui/icons/Home';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

import * as packageInfo from '../../../../package.json';

import appKey from 'util/auth';
import AuthorizedFeature from 'components/Auth/AuthorizedFeature';
import { enterpriseLinks, marketingLinks, adminLinks } from './routes';
import './styles.css';

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
  })
);
const drawerWidth = 240;

const CollapsableMenu = ({ location }) => {
  const [activeSection, setActiveSection] = useState('home');
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (section) => {
    if (activeSection === section) {
      return setActiveSection('home');
    } else {
      return setActiveSection(section);
    }
  };

  const handleMenuItemClick = (link) => {
    navigate(link);
  }

  return (
    <Drawer className="app-menu" variant="permanent" anchor="left">
      <List>
        <ListItem button className={classes.menuItem} onClick={() => handleMenuItemClick('/')}>
            <ListItemIcon className={classes.menuItemIcon}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
        </ListItem>

        {/* deprecated as of 1/3/2025 */}
        {/*<ListItem button onClick={() => handleClick(appKey.XCHANGE)} className={classes.menuItem}>*/}
        {/*  <ListItemIcon className={classes.menuItemIcon}>*/}
        {/*    <SwapHorizIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="Polygons" />*/}
        {/*  {activeSection === appKey.XCHANGE ? <IconExpandLess /> : <IconExpandMore />}*/}
        {/*</ListItem>*/}
        {/*<Collapse in={activeSection === appKey.XCHANGE} timeout="auto" unmountOnExit>*/}
        {/*  <Divider />*/}
        {/*  <List component="div" disablePadding>*/}
        {/*    {polygonLinks.map((link, i) => (*/}
        {/*      <AuthorizedFeature key={i} app={link.app} permissions={link.permissions}>*/}
        {/*        <ListItem key={i} button className={classes.menuItem} onClick={() => handleMenuItemClick(link.url)}>*/}
        {/*            <ListItemText inset primary={link.text} />*/}
        {/*        </ListItem>*/}
        {/*      </AuthorizedFeature>*/}
        {/*    ))}*/}
        {/*  </List>*/}
        {/*</Collapse>*/}

        <ListItem button onClick={() => handleClick(appKey.XTRACT)} className={classes.menuItem}>
          <ListItemIcon className={classes.menuItemIcon}>
            <SaveAltIcon />
          </ListItemIcon>
          <ListItemText primary="Enterprise" />
          {activeSection === appKey.XTRACT ? <IconExpandLess /> : <IconExpandMore />}
        </ListItem>
        <Collapse in={activeSection === appKey.XTRACT} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {enterpriseLinks.map((link, i) => (
              <AuthorizedFeature key={i} app={link.app} permissions={link.permissions}>
                <ListItem key={i} button className={classes.menuItem} onClick={() => handleMenuItemClick(link.url)}>
                  <ListItemText inset primary={link.text} />
                </ListItem>
              </AuthorizedFeature>
            ))}
          </List>
        </Collapse>

        <ListItem button onClick={() => handleClick(appKey.XCLUDE)} className={classes.menuItem}>
          <ListItemIcon className={classes.menuItemIcon}>
            <EnhancedEncryptionIcon />
          </ListItemIcon>
          <ListItemText primary="Marketing" />
          {activeSection === appKey.XCLUDE ? <IconExpandLess /> : <IconExpandMore />}
        </ListItem>
        <Collapse in={activeSection === appKey.XCLUDE} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {marketingLinks.map((link, i) => (
              <AuthorizedFeature key={i} app={link.app} permissions={link.permissions}>
                <ListItem key={i} button className={classes.menuItem}  onClick={() => handleMenuItemClick(link.url)}>
                  <ListItemText inset primary={link.text} />
                </ListItem>
              </AuthorizedFeature>
            ))}
          </List>
        </Collapse>

        <ListItem button onClick={() => handleClick(appKey.XCALIBUR)} className={classes.menuItem}>
          <ListItemIcon className={classes.menuItemIcon}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Admin" />
          {activeSection === appKey.XCALIBUR ? <IconExpandLess /> : <IconExpandMore />}
        </ListItem>
        <Collapse in={activeSection === appKey.XCALIBUR} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {adminLinks.map((link, i) => (
              <AuthorizedFeature key={i} app={link.app} permissions={link.permissions}>
                <ListItem key={i} button className={classes.menuItem} onClick={() => handleMenuItemClick(link.url)}>
                  <ListItemText inset primary={link.text} />
                </ListItem>
              </AuthorizedFeature>
            ))}
          </List>
        </Collapse>
      </List>
      <div style={{position: 'absolute', bottom: 0, left: 10}}>
        <p>
          Version: <span>{packageInfo.version}</span>
        </p>
      </div>
    </Drawer>
  );
};
export default CollapsableMenu;
